*
{
    margin: 0;
    padding: 0;
    color: black;
}

.main-slider
{
    width: 100%;
    height: 99vh;
    /* z-index: 0; */
}

video
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-content
{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white !important;
}

.overlay
{
    position: absolute;
    height: 99vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.text-white
{
    color: white !important;
}

.item {
    font-size: 32px;
    text-align: center;
    color: white;
  }

.wotd
{
    background-image: url(../Images/wotd-background.png);
    background-repeat: repeat;
    height: 300px;
    color: white !important;
}

.my-activities
{
    background-image: url('../Images/aotd-background.png');
    background-repeat: repeat;
    height: 500px;
}


.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
}